<div class="grid grid-cols-1 lg:grid-cols-10 gap-6">
  <div class="col-span-1 lg:col-span-7">
    <div class="mx-auto" id="general">
      <div
        class="font-efSans font-bold text-lg md:text-xl text-mat-ef-revolution-primary-500"
        *ngIf="!loading || user?.contactId"
      >
        <T
          str="Profile"
          key="customerPortal.customer-portal.profile"
          tags="customer-portal, 3.1"
        ></T>
      </div>

      <mat-divider class="relative w-full"></mat-divider>

      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div
              *ngIf="!user?.profilePictureUrl || showAltIcon"
              class="flex rounded-full text-white text-center font-efSans text-[10px] w-6 h-6 px-0.5 leading-5 my-auto uppercase bg-mat-ef-revolution-primary-500 cursor-pointer"
            >
              <span class="m-auto">{{ user | userinitials | async }}</span>
            </div>

            <div
              class="cursor-pointer rounded-full"
              *ngIf="user?.profilePictureUrl"
              [ngClass]="{
                'bg-mat-ef-revolution-primary-500 shadow-md h-7 w-7 p-[2px]':
                  !showAltIcon
              }"
              (click)="openUserImgDetails()"
            >
              <img
                *ngIf="!showAltIcon"
                class="h-6 w-6 object-cover rounded-full shadow-md"
                [src]="user?.profilePictureUrl"
                (error)="showAltIcon = true"
                alt="profile picture"
              />
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>
            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                <T
                  str="Profile picture"
                  key="customerPortal.customer-portal.details.avatar.title"
                  tags="customer-portal, 3.1"
                ></T>
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                class="font-efSans text-sm text-mat-ef-revolution-grey-two-500"
                *ngIf="!loading"
              >
                (<T
                  str="Visible only to me"
                  key="customerPortal.customer-portal.only-invisible-for-me"
                  tags="customer-portal, 3.1"
                ></T
                >)
              </div>
              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <button
            data-cy="edit-profile-picture-button"
            *ngIf="!user?.profilePictureUrl && !loading"
            mat-button
            mat-raised-button
            class="revolution hidden md:inline-flex small-button"
            color="secondary"
            (click)="
              openUserImgDetails();
              sendContentModulesMiniCTAData('shared.update')
            "
          >
            <mat-icon svgIcon="add_photo_alternate"></mat-icon>
            {{ 'update' | sharedTranslate }}
          </button>
          <mat-icon
            *ngIf="!user?.profilePictureUrl && !loading"
            svgIcon="add_photo_alternate"
            class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
            (click)="openUserImgDetails()"
          ></mat-icon>
          <button
            data-cy="edit-profile-picture-button"
            *ngIf="user?.profilePictureUrl && !loading"
            mat-button
            mat-raised-button
            class="revolution hidden md:inline-flex small-button"
            color="secondary"
            (click)="
              openUserImgDetails();
              sendContentModulesMiniCTAData('shared.update')
            "
          >
            <mat-icon svgIcon="create"></mat-icon>
            <ng-container *ngTemplateOutlet="change"></ng-container>
          </button>
          <mat-icon
            *ngIf="user?.profilePictureUrl && !loading"
            svgIcon="create"
            class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
            (click)="openUserImgDetails()"
          ></mat-icon>
        </div>
      </div>
      <mat-divider class="relative w-full"></mat-divider>

      <div
        class="font-efSans font-bold text-lg md:text-xl text-mat-ef-revolution-primary-500 mt-12"
        *ngIf="!loading || user?.contactId"
      >
        <T
          str="Contact details"
          key="customerPortal.customer-portal.contact.details"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div *ngIf="loading && !user?.contactId" class="heading-small-skeleton">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>

      <mat-divider class="relative w-full"></mat-divider>

      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div class="mt-2.5" *ngIf="!loading">
              <mat-icon
                class="text-mat-ef-revolution-primary-500"
                svgIcon="person_outline"
              ></mat-icon>
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                <T
                  str="Salutation and name"
                  key="customerPortal.customer-portal.details.personal.salutation-and-name"
                  tags="customer-portal, 3.1"
                ></T>
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && !user.isBusiness"
              >
                {{ user | username: true | async }}
              </div>
              <div
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && user.isBusiness"
              >
                {{ user.companyName }}
              </div>
              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <button
            *ngIf="!notEditable && user && !isPerson() && !loading"
            mat-button
            mat-stroked-button
            class="revolution hidden md:inline-flex small-button"
            color="secondary"
            (click)="
              openNameDialog(user);
              sendContentModulesMiniCTAData('shared.update')
            "
          >
            <mat-icon *ngIf="!loading" svgIcon="create"> </mat-icon>
            <ng-container *ngTemplateOutlet="change"></ng-container>
          </button>
          <mat-icon
            *ngIf="!notEditable && user && !isPerson() && !loading"
            svgIcon="create"
            class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
            (click)="openNameDialog(user)"
          ></mat-icon>
        </div>
      </div>

      <mat-divider class="relative w-full"></mat-divider>

      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div class="mt-2.5" *ngIf="!loading">
              <mat-icon
                class="text-mat-ef-revolution-primary-500"
                svgIcon="home"
              ></mat-icon>
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                {{ 'address' | sharedTranslate }}
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                data-cy="user-address-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && user?.address?.[0]"
              >
                {{ user?.address[0].street ? user?.address[0].street : '' }}
                {{
                  user?.address[0].streetNumber
                    ? user?.address[0].streetNumber + ', '
                    : ', '
                }}
                {{
                  user?.address[0].streetAdditional
                    ? user?.address[0].streetAdditional + ', '
                    : ''
                }}
                {{ user?.address[0].zip ? user?.address[0].zip : '' }}
                {{ user?.address[0].city ? user?.address[0].city : '' }}
              </div>
              <div
                data-cy="user-address-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && !user?.address?.[0]"
              >
                -
              </div>
              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <button
            data-cy="edit-address-button"
            *ngIf="!notEditable && isPerson() && !loading"
            mat-button
            mat-stroked-button
            class="revolution hidden md:inline-flex small-button"
            color="secondary"
            (click)="
              openAddressDialog(user);
              sendContentModulesMiniCTAData('shared.update')
            "
          >
            <mat-icon *ngIf="!loading" svgIcon="create"> </mat-icon>
            <ng-container *ngTemplateOutlet="change"></ng-container>
          </button>
          <mat-icon
            svgIcon="create"
            *ngIf="!notEditable && isPerson() && !loading"
            class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
            (click)="openAddressDialog(user)"
          ></mat-icon>
        </div>
      </div>
      <mat-divider class="relative w-full"></mat-divider>

      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div class="mt-2.5" *ngIf="!loading">
              <mat-icon
                class="text-mat-ef-revolution-primary-500"
                svgIcon="stay_current_portrait"
              ></mat-icon>
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                {{ 'mobile-number' | sharedTranslate }}
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                data-cy="user-mobilePersonal-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && mobile?.mask"
              >
                {{ mobile.prefix }} {{ mobile.phone | mask: mobile.mask }}
              </div>
              <div
                data-cy="user-mobilePersonal-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && !mobile?.mask"
              >
                {{ mobile ? mobile.number : '-' }}
              </div>

              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="flex"
            data-cy="edit-user-mobilePersonal"
          >
            <button
              data-cy="edit-user-mobilePersonal-button"
              *ngIf="!notEditable && !loading && !mobile"
              mat-button
              mat-stroked-button
              class="revolution hidden md:inline-flex small-button"
              color="secondary"
              (click)="
                openTelephoneDialog(user, 'private');
                sendContentModulesMiniCTAData('shared.update')
              "
            >
              <mat-icon svgIcon="create"></mat-icon>
              <ng-container *ngTemplateOutlet="change"></ng-container>
            </button>
            <mat-icon
              *ngIf="!notEditable && !loading && !mobile"
              svgIcon="create"
              class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
              (click)="openTelephoneDialog(user, 'private')"
            ></mat-icon>
            <div *ngIf="!notEditable && !loading && mobile" class="cptMenuContainer p-0">
              <customer-portal-app-cpt-menu
                data-cy="edit-user-mobilePersonal-menu"
                [menuType]="'editDeleteMenu'"
                (actionClicked)="mobileActionClicked($event, user)"
              >
              </customer-portal-app-cpt-menu>
            </div>
          </div>
        </div>
      </div>
      <mat-divider class="relative w-full"></mat-divider>

      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div class="mt-2.5" *ngIf="!loading">
              <mat-icon
                class="text-mat-ef-revolution-primary-500"
                svgIcon="phone"
              ></mat-icon>
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                <T
                  str="Landline"
                  key="customerPortal.customer-portal.details.personal.landline"
                  tags="customer-portal, 3.1"
                ></T>
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                data-cy="user-landlinePersonal-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && landlinePersonal?.mask"
              >
                {{ landlinePersonal.prefix }}
                {{ landlinePersonal.phone | mask: landlinePersonal.mask }}
              </div>
              <div
                data-cy="user-landlinePersonal-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && !landlinePersonal?.mask"
              >
                {{ landlinePersonal ? landlinePersonal.number : '-' }}
              </div>

              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <div
            class="flex"
            *ngIf="!notEditable && !loading"
            data-cy="edit-user-landlinePersonal"
          >
            <button
              data-cy="edit-user-landlinePersonal-button"
              *ngIf="!landlinePersonal"
              mat-button
              mat-stroked-button
              class="revolution hidden md:inline-flex small-button"
              color="secondary"
              (click)="
                openNumberLandline(user);
                sendContentModulesMiniCTAData('shared.update')
              "
            >
              <mat-icon svgIcon="create"></mat-icon>
              <ng-container *ngTemplateOutlet="change"></ng-container>
            </button>
            <mat-icon
              *ngIf="!landlinePersonal"
              svgIcon="create"
              class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
              (click)="openNumberLandline(user)"
            >
            </mat-icon>
            <div *ngIf="landlinePersonal" class="cptMenuContainer p-0">
              <customer-portal-app-cpt-menu
                data-cy="edit-user-landlinePersonal-menu"
                [menuType]="'editDeleteMenu'"
                (actionClicked)="landlinePersonalActionClicked($event, user)"
              >
              </customer-portal-app-cpt-menu>
            </div>
          </div>
        </div>
      </div>
      <mat-divider class="relative w-full"></mat-divider>

      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div class="mt-2.5" *ngIf="!loading">
              <mat-icon
                class="text-mat-ef-revolution-primary-500"
                svgIcon="stay_current_portrait"
              ></mat-icon>
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                <T
                  str="Company number - mobile"
                  key="customerPortal.customer-portal.details.business.mobile"
                  tags="customer-portal, 3.1"
                ></T>
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                data-cy="user-mobileBusiness-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && mobileBusiness?.mask"
              >
                {{ mobileBusiness.prefix }}
                {{ mobileBusiness.phone | mask: mobileBusiness.mask }}
              </div>
              <div
                data-cy="user-mobileBusiness-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && !mobileBusiness?.mask"
              >
                {{ mobileBusiness ? mobileBusiness.number : '-' }}
              </div>

              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="flex"
            data-cy="edit-user-mobileBusiness"
          >
            <button
             data-cy="edit-user-mobileBusiness-button"
              *ngIf="!notEditable && !loading && !mobileBusiness"
              mat-button
              mat-stroked-button
              class="revolution hidden md:inline-flex small-button"
              color="secondary"
              (click)="
                openTelephoneDialog(user, 'business');
                sendContentModulesMiniCTAData('shared.update')
              "
            >
              <mat-icon svgIcon="create"></mat-icon>
              <ng-container *ngTemplateOutlet="change"></ng-container>
            </button>
            <mat-icon
              *ngIf="!notEditable && !loading && !mobileBusiness"
              svgIcon="create"
              class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
              (click)="openTelephoneDialog(user, 'business')"
            ></mat-icon>
            <div
              *ngIf="!notEditable && !loading && mobileBusiness"
              class="cptMenuContainer p-0"
            >
              <customer-portal-app-cpt-menu
                data-cy="edit-user-mobileBusiness-menu"
                [menuType]="'editDeleteMenu'"
                (actionClicked)="mobileBusinessActionClicked($event, user)"
              >
              </customer-portal-app-cpt-menu>
            </div>
          </div>
        </div>
      </div>
      <mat-divider class="relative w-full"></mat-divider>

      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div class="mt-2.5" *ngIf="!loading">
              <mat-icon
                class="text-mat-ef-revolution-primary-500"
                svgIcon="phone"
              ></mat-icon>
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                <T
                  str="Company number - landline"
                  key="customerPortal.customer-portal.details.business.landline"
                  tags="customer-portal, 3.1"
                ></T>
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                data-cy="user-landlineBusiness-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && landlineBusiness?.mask"
              >
                {{ landlineBusiness.prefix }}
                {{ landlineBusiness.phone | mask: landlineBusiness.mask }}
              </div>
              <div
                data-cy="user-landlineBusiness-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading && !landlineBusiness?.mask"
              >
                {{ landlineBusiness ? landlineBusiness.number : '-' }}
              </div>

              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <div
            class="flex"
            data-cy="edit-user-landlineBusiness"
            *ngIf="!notEditable && !loading"
          >
            <button
              data-cy="edit-user-landlineBusiness-button"
              *ngIf="!landlineBusiness"
              mat-button
              mat-stroked-button
              class="revolution hidden md:inline-flex small-button"
              color="secondary"
              (click)="
                openCompanyNumberLandline(user);
                sendContentModulesMiniCTAData('shared.update')
              "
            >
              <mat-icon svgIcon="create"></mat-icon>
              <ng-container *ngTemplateOutlet="change"></ng-container>
            </button>
            <mat-icon
              svgIcon="create"
              *ngIf="!landlineBusiness"
              class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
              (click)="openCompanyNumberLandline(user)"
            ></mat-icon>
            <div *ngIf="landlineBusiness" class="cptMenuContainer p-0">
              <customer-portal-app-cpt-menu
                data-cy="edit-user-landlineBusiness-menu"
                [menuType]="'editDeleteMenu'"
                (actionClicked)="landlineBusinessActionClicked($event, user)"
              >
              </customer-portal-app-cpt-menu>
            </div>
          </div>
        </div>
      </div>
      <mat-divider class="relative w-full"></mat-divider>

      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div class="mt-2.5" *ngIf="!loading">
              <mat-icon
                class="text-mat-ef-revolution-primary-500"
                svgIcon="cake"
              ></mat-icon>
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                {{ 'date-of-birth' | sharedTranslate }}
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                data-cy="user-birthday-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                {{
                  user && user?.birthDate
                    ? (user?.birthDate | date: dateFormat)
                    : '-'
                }}
              </div>
              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <button
            *ngIf="!notEditable && !loading"
            data-cy="edit-birthday-button"
            mat-button
            mat-stroked-button
            class="revolution hidden md:inline-flex small-button"
            color="secondary"
            (click)="
              openBirthdateDialog(user);
              sendContentModulesMiniCTAData('shared.update')
            "
          >
            <mat-icon svgIcon="create"></mat-icon>
            <ng-container *ngTemplateOutlet="change"></ng-container>
          </button>
          <mat-icon
            *ngIf="!notEditable && !loading"
            svgIcon="create"
            class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
            (click)="openBirthdateDialog(user)"
          ></mat-icon>
        </div>
      </div>

      <mat-divider class="relative w-full"></mat-divider>

      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div class="mt-2.5" *ngIf="!loading">
              <mat-icon
                class="text-mat-ef-revolution-primary-500"
                svgIcon="drivers_license"
              ></mat-icon>
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                {{ 'care-id' | sharedTranslate }}
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                data-cy="user-careId-text"
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                {{ user?.careId ?? '-' }}
              </div>
              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
      </div>

      <mat-divider class="relative w-full"></mat-divider>

      <div
        class="font-efSans font-bold text-lg md:text-xl text-mat-ef-revolution-primary-500 mt-12"
        *ngIf="!loading || user?.contactId"
      >
        <T
          str="Login details"
          key="customerPortal.customer-portal.credentials"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div *ngIf="loading && !user?.contactId" class="heading-small-skeleton">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>

      <mat-divider class="relative w-full"></mat-divider>
      <mat-expansion-panel
        #email
        [disabled]="!hasPendingEmailChange()"
        (afterExpand)="
          sendContentModulesData(
            true,
            'customerPortal.customer-portal.details.login.email'
          )
        "
        (afterCollapse)="
          sendContentModulesData(
            false,
            'customerPortal.customer-portal.details.login.email'
          )
        "
        class="py-0 pr-0"
      >
        <mat-expansion-panel-header
          class="px-0"
          [collapsedHeight]="'auto'"
          [expandedHeight]="'auto'"
          data-cy="email-change-section"
        >
          <mat-panel-title class="mr-0">
            <div class="header flex items-center justify-between w-full">
              <div class="flex items-center gap-6">
                <div class="mt-2.5" *ngIf="!loading">
                  <mat-icon
                    class="text-mat-ef-revolution-primary-500"
                    svgIcon="alternate_email"
                  ></mat-icon>
                </div>
                <div class="mt-2.5" *ngIf="loading">
                  <ngx-skeleton-loader
                    appearance="line"
                    animation="progress"
                  ></ngx-skeleton-loader>
                </div>

                <div class="text">
                  <div
                    class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                    *ngIf="!loading"
                  >
                    <T
                      str="E-Mail-Adresse"
                      key="customerPortal.customer-portal.details.login.email"
                      tags="customer-portal, 3.1"
                    ></T>
                    <div
                      *ngIf="hasPendingEmailChange()"
                      class="hasPendingEmailChange"
                    >
                      <T
                        str="Email address change pending"
                        key="customerPortal.customer-portal.details.pending-email-change"
                        tags="customer-portal, 3.1"
                      ></T>
                    </div>
                  </div>
                  <div *ngIf="loading">
                    <ngx-skeleton-loader
                      appearance="line"
                      animation="progress"
                    ></ngx-skeleton-loader>
                  </div>

                  <div
                    class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                    data-cy="user-email"
                    *ngIf="!loading"
                  >
                    {{ user?.email }}
                  </div>
                  <div *ngIf="loading" class="skeleton-margin">
                    <ngx-skeleton-loader
                      appearance="line"
                      animation="progress"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
              <div *ngIf="!notEditable && !hasPendingEmailChange()">
                <button
                  mat-button
                  mat-stroked-button
                  class="revolution hidden md:inline-flex small-button mr-[2px]"
                  color="secondary"
                  data-cy="edit-user-email-button"
                  (click)="
                    openEmailDialog(user);
                    sendContentModulesMiniCTAData('shared.update')
                  "
                >
                  <mat-icon svgIcon="create"></mat-icon>
                  <ng-container *ngTemplateOutlet="change"></ng-container>
                </button>
                <mat-icon
                  svgIcon="create"
                  class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
                  (click)="openEmailDialog(user)"
                >
                </mat-icon>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="ml-6 mt-2">
          <div class="flex flex-col md:flex-row gap-6">
            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
              >
                <T
                  str="New email address"
                  key="customerPortal.customer-portal.details.pending-email-change.new-mail"
                  tags="customer-portal, 3.1"
                ></T>
              </div>

              <div
                class="font-efSans text-sm text-mat-ef-revolution-primary-500 font-normal"
              >
                {{ emailChangeDetails?.newEmail }}
              </div>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
              >
                <T
                  str="Valid until"
                  key="customerPortal.customer-portal.details.pending-email-change.valid-until"
                  tags="customer-portal, 3.1"
                ></T>
              </div>

              <div
                class="font-efSans text-sm text-mat-ef-revolution-primary-500 font-normal"
              >
                {{
                  emailChangeDetails?.validTo
                    ? (emailChangeDetails?.validTo | date: 'dd.MM.yyyy HH:mm')
                    : '-'
                }}
              </div>
            </div>
          </div>

          <ng-container *ngIf="!notEditable">
          <mat-divider class="relattive w-full my-4"></mat-divider>

          <div class="relative -r-10 w-full mt-5">
            <button
              class="revolution float-right"
              mat-button
              mat-raised-button
              color="secondary"
              data-cy="pending-edit-user-email-button"
              (click)="openEmailDialog(user)"
            >
              <T
                str="Update e-mail"
                key="customerPortal.customer-portal.details.change.email"
                tags="customer-portal, 3.1"
              ></T>
            </button>
          </div>
          </ng-container>

        </div>
      </mat-expansion-panel>
      <mat-divider class="relative w-full"></mat-divider>
      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div class="mt-2.5" *ngIf="!loading">
              <mat-icon
                class="text-mat-ef-revolution-primary-500"
                svgIcon="lock"
              ></mat-icon>
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                {{ 'password' | sharedTranslate }}
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                <T
                  str="Last updated on:"
                  key="customerPortal.customer-portal.details.password-message"
                  tags="customer-portal, 3.1"
                ></T>
                {{ date ? (date | date: dateFormat) : ' - ' }}
              </div>
              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <button
            *ngIf="!loading"
            mat-button
            mat-stroked-button
            class="revolution hidden md:inline-flex small-button"
            color="secondary"
            (click)="
              openPasswordDialog(user);
              sendContentModulesMiniCTAData('shared.update')
            "
          >
            <mat-icon svgIcon="create"></mat-icon>
            <ng-container *ngTemplateOutlet="change"></ng-container>
          </button>
          <mat-icon
            *ngIf="!loading"
            svgIcon="create"
            class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
            (click)="openPasswordDialog(user)"
          ></mat-icon>
        </div>
      </div>

      <mat-divider class="relative w-full"></mat-divider>

      <div
        class="font-efSans font-bold text-lg md:text-xl text-mat-ef-revolution-primary-500 mt-12"
        *ngIf="!loading || user?.contactId"
      >
        <T
          str="Account settings"
          key="customerPortal.customer-portal.account-settings"
          tags="customer-portal, 3.1"
        ></T>
      </div>
      <div *ngIf="loading && !user?.contactId" class="heading-small-skeleton">
        <ngx-skeleton-loader
          appearance="line"
          animation="progress"
        ></ngx-skeleton-loader>
      </div>
      <mat-divider class="relative w-full"></mat-divider>
      <div class="panel h-15 mx-0">
        <div
          class="header flex items-center justify-between w-full mr-10 p-2.5"
        >
          <div class="flex items-center gap-6">
            <div class="mt-2.5" *ngIf="!loading">
              <mat-icon
                class="text-mat-ef-revolution-primary-500"
                svgIcon="language"
              ></mat-icon>
            </div>
            <div class="mt-2.5" *ngIf="loading">
              <ngx-skeleton-loader
                appearance="line"
                animation="progress"
              ></ngx-skeleton-loader>
            </div>

            <div class="text">
              <div
                class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                <T
                  str="Language"
                  key="customerPortal.customer-portal.details.language"
                  tags="customer-portal, 3.1"
                ></T>
              </div>
              <div *ngIf="loading">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>

              <div
                class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                *ngIf="!loading"
              >
                {{ lang ? lang : '-' }}
              </div>
              <div *ngIf="loading" class="skeleton-margin">
                <ngx-skeleton-loader
                  appearance="line"
                  animation="progress"
                ></ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <button
            *ngIf="!notEditable && !loading"
            mat-button
            mat-stroked-button
            class="revolution hidden md:inline-flex small-button"
            color="secondary"
            (click)="
              openLanguageDialog(user);
              sendContentModulesMiniCTAData('shared.update')
            "
          >
            <mat-icon svgIcon="create"></mat-icon>
            <ng-container *ngTemplateOutlet="change"></ng-container>
          </button>
          <mat-icon
            *ngIf="!notEditable && !loading"
            svgIcon="create"
            class="inline-flex md:hidden text-mat-ef-revolution-secondary-500 cursor-pointer"
            (click)="openLanguageDialog(user)"
          ></mat-icon>
        </div>
      </div>

      <mat-divider class="relative w-full"></mat-divider>
      <mat-expansion-panel
        (afterExpand)="sendContentModulesData(true, 'shared.account-delete')"
        (afterCollapse)="sendContentModulesData(false, 'shared.account-delete')"
        #email
        class="py-0 pr-0"
      >
        <mat-expansion-panel-header
          class="px-0"
          [collapsedHeight]="'auto'"
          [expandedHeight]="'auto'"
        >
          <mat-panel-title class="mr-0">
            <div class="header flex items-center justify-between w-full">
              <div class="flex items-center gap-6">
                <div class="mt-2.5" *ngIf="!loading">
                  <mat-icon
                    class="text-mat-ef-revolution-primary-500"
                    svgIcon="account_circle"
                  ></mat-icon>
                </div>
                <div class="mt-2.5" *ngIf="loading">
                  <ngx-skeleton-loader
                    appearance="line"
                    animation="progress"
                  ></ngx-skeleton-loader>
                </div>

                <div class="text">
                  <div
                    class="font-efSans text-sm font-bold text-mat-ef-revolution-primary-500"
                    *ngIf="!loading"
                  >
                    {{ 'account-delete' | sharedTranslate }}
                  </div>
                  <div *ngIf="loading">
                    <ngx-skeleton-loader
                      appearance="line"
                      animation="progress"
                    ></ngx-skeleton-loader>
                  </div>

                  <div
                    class="font-efSans text-sm text-mat-ef-revolution-primary-500"
                    *ngIf="!loading"
                  >
                    <T
                      str="Delete your account"
                      key="customerPortal.customer-portal.details.delete-account-message"
                      tags="customer-portal, 3.1"
                    ></T>
                  </div>
                  <div *ngIf="loading" class="skeleton-margin">
                    <ngx-skeleton-loader
                      appearance="line"
                      animation="progress"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="ml-6">
          <div class="grid gap-6">
            <h3 class="font-efSans text-lg text-mat-ef-revolution-red-500 mt-6">
              <T
                str="Important NOTE!"
                key="customerPortal.customer-portal.details.delete.account.heading"
                tags="customer-portal, 3.1"
              ></T>
            </h3>
            <p
              class="delete-consent font-efSans text-sm text-mat-ef-revolution-primary-500 text-justify font-normal"
            >
              <T
                str="After deleting your Emil Frey customer portal account, you can no longer log in to the customer portal with your access data. The deletion of the account in the customer portal has no effect on other accounts that you have created on the websites and applications of the Emil Frey Group. The data on your other Emil Frey Group accounts will be retained. If you wish, you can register again for the Emil Frey customer portal with new access data. In doing so, however, you will lose the previous settings and all the data you uploaded in the deleted account. Before you delete your account, please download your files and images uploaded in the customer portal to avoid data loss."
                key="customerPortal.customer-portal.details.delete.account.note"
                tags="customer-portal, 3.1"
              ></T>
            </p>
            <mat-checkbox
              [formControl]="note"
              (change)="checkIfSelected()"
              color="primary"
              class="consent-checkbox font-efSans text-sm text-mat-ef-revolution-primary-500 text-justify leading-none"
            >
              <T
                str="I have understood the information and I agree that my myEmil Frey customer portal account will definitely be deleted."
                key="customerPortal.customer-portal.details.delete.account.consent"
                tags="customer-portal, 3.1"
              ></T>
            </mat-checkbox>
          </div>
          <div class="relative -r-10 w-full mt-5">
            <button
              mat-button
              mat-stroked-button
              class="revolution float-right"
              [disabled]="disable"
              (click)="deleteAccount(user)"
              color="warn"
            >
              <mat-icon svgIcon="delete"></mat-icon>
              {{ 'account-delete' | sharedTranslate }}
            </button>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-divider class="relative w-full"></mat-divider>
    </div>
  </div>

  <div class="col-span-1 lg:col-span-3 flex flex-col gap-6">
    <div
      *ngIf="hasDrivingLicense"
      class="rounded-xl bg-mat-ef-revolution-grey-200 mt-11"
    >
      <div class="p-6">
        <p
          class="font-efSans text-[15px] font-bold text-mat-ef-revolution-primary-500 mb-3"
        >
          <T
            str="Driver's license"
            key="customerPortal.customer-portal.details.driving-licence"
            tags="customer-portal, 3.1"
          ></T>
        </p>

        <p
          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
          *ngIf="!documents.back && !documents.front"
        >
          <T
            str="Save time during your next visit at the dealership and add your driving licence to your profile."
            key="customerPortal.customer-portal.driver-licence-to-your-profile"
            tags="customer-portal, 3.1"
          ></T>
        </p>
        <p
          class="font-efSans text-sm text-mat-ef-revolution-primary-500"
          *ngIf="documents.back || documents.front"
        >
          <T
            str="Manage your driving licence documents"
            key="customerPortal.customer-portal.manage-your-driving-licence"
            tags="customer-portal, 3.1"
          ></T>
        </p>

        <div
          class="driver-licence mt-5 gap-3"
          *ngIf="documents.back || documents.front"
        >
          <div
            data-cy="edit-driving-license-front"
            [matTooltip]="tooltipFrontText"
            class="w-full col-span-1 lg:w-[118px] h-40 lg:h-[73px] flex flex-wrap align-center relative cursor-pointer bg-white rounded-lg"
            (click)="loadLicence('front')"
          >
            <div
              class="w-full flex justify-center items-center cursor-pointer"
              *ngIf="!documents.front || documents.front === 'front'"
            >
              <mat-icon
                svgIcon="add_photo_alternate"
                class="text-xs ml-2 text-mat-ef-revolution-primary-500"
              ></mat-icon>
            </div>

            <ng-container
              *ngIf="documents.front && documents.front !== 'front'"
            >
              <div
                class="image-preview rounded-lg bg-center bg-cover z-10 absolute cursor-pointer w-full h-full"
              >
                <img
                  alt="document - front"
                  [src]="documents.front"
                  class="w-full h-full rounded-lg"
                />
              </div>

              <div class="absolute r-2.5 t-2.5 z-10 opacity-0">
                <button mat-button>
                  <mat-icon
                    class="text-mat-ef-revolution-primary-500"
                    svgIcon="create"
                  ></mat-icon>
                </button>
              </div>
            </ng-container>
          </div>

          <div
            data-cy="edit-driving-license-back"
            [matTooltip]="tooltipBackText"
            class="w-full col-span-1 lg:w-[118px] h-40 lg:h-[73px] flex flex-wrap align-center relative cursor-pointer bg-white rounded-lg"
            (click)="loadLicence('back')"
          >
            <div
              class="w-full flex justify-center items-center cursor-pointer"
              *ngIf="!documents.back || documents.back === 'back'"
            >
              <mat-icon
                svgIcon="add_photo_alternate"
                class="text-xs ml-2 text-mat-ef-revolution-primary-500"
              ></mat-icon>
            </div>

            <ng-container *ngIf="documents.back && documents.back !== 'back'">
              <div
                class="image-preview rounded-lg bg-center bg-cover z-10 absolute cursor-pointer w-full h-full"
              >
                <img
                  alt="document - back"
                  [src]="documents.back"
                  class="w-full h-full rounded-lg"
                />
              </div>

              <div class="absolute r-2.5 t-2.5 z-10 opacity-0">
                <button mat-button>
                  <mat-icon
                    class="text-mat-ef-revolution-primary-500"
                    svgIcon="create"
                  ></mat-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </div>

        <button
          data-cy="edit-driving-license-upload-now"
          mat-button
          mat-stroked-button
          *ngIf="!documents.back && !documents.front"
          class="revolution medium-button mt-4"
          color="secondary"
          (click)="
            documents.back = 'back';
            documents.front = 'front';
            sendContentModulesCTAData(
              'customerPortal.customer-portal.upload.now'
            )
          "
        >
          <T
            str="Upload now"
            key="customerPortal.customer-portal.upload.now"
            tags="customer-portal, 3.1"
          ></T>
        </button>
      </div>
    </div>

    <customer-portal-app-faq-card
      *ngIf="hasFAQ"
      [alwaysSmall]="true"
    ></customer-portal-app-faq-card>

    <customer-portal-app-user-notice-card
      [mode]="'generalUserData'"
      (showUserNoticeCard)="setShowUserNoticeCard($event)"
    >
    </customer-portal-app-user-notice-card>
  </div>
</div>

<ng-template #change>
  {{ 'update' | sharedTranslate }}
</ng-template>
